/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&family=Roboto:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');

/* @font-face {
  font-family: 'NotoSansKR';
  font-weight: 400;
  font-style: normal;
  src: url('./asset/fonts/NotoSansKR-Medium.woff2') format('woff2'),
  url('./asset/fonts/NotoSansKR-Medium.woff') format('woff'),
  url('./asset/fonts/NotoSansKR-Medium.otf') format('opentype')
}
@font-face {
  font-family: 'NotoSansKR';
  font-weight: 500;
  font-style: normal;
  src: url('./asset/fonts/NotoSansKR-Medium.woff2') format('woff2'),
  url('./asset/fonts/NotoSansKR-Medium.woff') format('woff'),
  url('./asset/fonts/NotoSansKR-Medium.otf') format('opentype')
}
@font-face {
  font-family: 'NotoSansKR';
  font-weight: 700;
  font-style: normal;
  src: url('./asset/fonts/NotoSansKR-Bold.woff2') format('woff2'),
  url('./asset/fonts/NotoSansKR-Bold.woff') format('woff'),
  url('./asset/fonts/NotoSansKR-Bold.otf') format('opentype')
}
@font-face {
  font-family: 'NotoSansKR';
  font-weight: 900;
  font-style: normal;
  src: url('./asset/fonts/NotoSansKR-Bold.woff2') format('woff2'),
  url('./asset/fonts/NotoSansKR-Bold.woff') format('woff'),
  url('./asset//fonts/NotoSansKR-Bold.otf') format('opentype')
} */

@font-face {
  font-family: 'Cafe24 Ssurround';
  src: url('./asset/fonts/Cafe24Ssurround.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Cafe24 Ssurround air';
  src: url('./asset/fonts/Cafe24SsurroundAir.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Campton';
  font-weight: 400;
  src: url('./asset/fonts/campton-book-webfont.woff') format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}

@font-face {
  font-family: 'Campton';
  font-weight: 500;
  src: url('./asset/fonts/campton-medium-webfont.woff') format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}

@font-face {
  font-family: 'Campton';
  font-weight: 700;
  src: url('./asset/fonts/campton-semibold-webfont.woff') format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}

@font-face {
  font-family: 'Campton';
  font-weight: 900;
  src: url('./asset/fonts/campton-bold-webfont.woff') format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}

@font-face {
  font-family: 'NanumSquareOTF';
  src: url('./asset/fonts/NanumSquareOTFBold.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'NanumSquareOTF';
  src: url('./asset/fonts/NanumSquareOTFBold.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'NanumSquareOTF';
  src: url('./asset/fonts/NanumSquareOTFExtraBold.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'NanumSquareOTF';
  src: url('./asset/fonts/NanumSquareOTFExtraBold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'NanumSquareOTF';
  src: url('./asset/fonts/NanumSquareOTFExtraBold.woff') format('woff');
  font-weight: 900;
}

.search_result_title {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: -0.58px;
  color: var(--text-black);
}

.search_result_sub {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.54px;
  color: var(--text-black);
}

.search_result_kcal {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.54px;
  color: var(--text-grey);
}

.typing_on {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1.15px;
  text-align: center;
  color: var(--text-black);
}

.typing_off {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: -1.15px;
  text-align: center;
  color: var(--text-light-grey);
}

:root {
  /*--pimary-color-mint: #82c8d2;*/
  /*--text-black: #000;*/
  /*--pimary-color: #feffa0;*/
  /*--pimary-color: #284164;*/
  /*--squash: #ff4800;*/
  /*--pimary-color: #284164;*/
  /*--urinePaperColors-accent-blue: #007aff;*/
  /*--text-light-grey: #d0d2d6;*/
  /*--urinePaperColors-basic-destructive: #ff3b30;*/
  --pimary-color-light-mint: #82c8d2;
  --pimary-color-mint: #69b9c4;
  --pimary-color-light-purple: #8796d7;
  --pimary-color-purple: #7283cb;
  --pimary-color-navy: #284164;
  --pimary-color-yellow: #feffa0;
  --pimary-color-light-orange: #ffa064;
  --pimary-color-orange: #fa8c50;
  --point-blue: #007aff;
  --point-red: #ff4800;
  --text-black: #000;
  --text-grey: #999fab;
  --text-light-grey: #d0d2d6;
  --bg: #e6e7ea;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/*html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, input::placeholder, select, textarea, button, span, p, a, .MuiTypography-root {*/
/*  font-family: 'NotoSansKR';*/
/*}*/

body {
  margin: 0;
  font-family: 'Campton', 'Noto Sans KR';
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::placeholder {
  color: var(--text-light-grey);
  caret-color: #000;
}

button {
  border: none;
  outline: none;
  background: none;
  padding: 0px;
  line-height: 1;
}

.swiper-wrapper {
  display: -webkit-inline-box;
}

.typing_on {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1.15px;
  text-align: center;
  color: var(--text-black);
}
.typing_off {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: -1.15px;
  text-align: center;
  color: var(--text-light-grey);
}
.typing_on_KR {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: -0.96px;
  text-align: center;
  color: var(--text-black);
}
.typing_off_KR {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: -0.96px;
  text-align: center;
  color: var(--text-light-grey);
}
.btn_on {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: -0.81px;
  color: var(--text-black);
}
.btn_off {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: -0.81px;
  color: var(--text-light-grey);
}
.H1__ {
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.81px;
  color: #fff;
}
.H2_-_ {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.77px;
  color: var(--text-black);
}
.P_ {
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  text-align: center;
  color: var(--text-black);
}
.nutrition_info_title_g {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: -0.62px;
  text-align: right;
  color: var(--text-black);
}
.H3_-_ {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.62px;
  text-align: center;
  color: var(--text-black);
}
.nutrition_info_sub_g {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: -0.62px;
  text-align: right;
  color: #959ba9;
}
.btn__on {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.54px;
  color: var(--text-black);
}
.btn__off {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.54px;
  color: var(--text-light-grey);
}
.nutrition_info_title_name {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.54px;
  color: var(--text-black);
}
.nutrition_info_sub_name {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.54px;
  color: #959ba9;
}
.info1_-_ {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.54px;
  text-align: center;
  color: var(--text-grey);
}
.info2__ {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.46px;
  color: var(--text-black);
}

.intro-header {
  font-family: NanumSquareOTFB;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: -2.27px;
  text-align: center;
  color: var(--text-black);
}

.intro-header-res {
  font-family: NanumSquareOTFB;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: -1.87px;
  text-align: center;
  color: var(--text-black);
}

.intro-headerS {
  font-family: NanumSquareOTFB;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.69px;
  text-align: center;
  color: var(--text-black);
}

.MuiInputBase-inputAdornedEnd {
  text-align: right;
}

input,
input::placeholder {
  font-weight: 500 !important;
}

p {
  padding: 0;
  margin: 0;
}

.point-blue {
  color: var(--point-blue);
  font-weight: bold;
}

.slick-track {
  display: flex !important;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* .slick-slide {
  height: auto;
} */
.DayPicker {
  width: 375px;
  height: 697px;
}
.DayPicker-wrapper {
  width: 100%;
  height: 100%;
}
.DayPicker-Body {
  height: 310px;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #e1e4e7 !important;
  color: var(--text-black) !important;
  font-weight: 500;
}
.DayPicker-Day {
  font-weight: 500;
  font-size: 15px;
}

.close {
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: -8px;
  width: 8px;
  height: 8px;
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 12px;
  width: 1.2px;
  background-color: #d0d2d6;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.makeStyles-on-9 {
  background-color: var(--text-black) !important;
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.clickable {
  cursor: pointer;
}

.MuiMenuItem-root {
  font-family: 'Campton', 'Noto Sans KR';
  padding: 20px !important;
  overflow: visible;
}

.inputText {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.69px;
}

.count {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: var(--text-grey);
}
.MuiSnackbar-anchorOriginBottomCenter {
  border-radius: 5px;
  bottom: 20% !important;
}
.MuiSnackbarContent-root {
  background-color: rgba(55, 59, 60, 0.5) !important;
  min-width: 183px !important;
  font-size: 13px !important;
  font-family: NotoSansKR;
}
.MuiSnackbarContent-message {
  width: 100%;
  text-align: center;
}

.recharts-cartesian-axis-tick {
  font-size: 11px;
  font-weight: 600;
  color: #5b689f;
}

.recharts-cartesian-axis-tick > text {
  color: #5b689f;
  fill: #5b689f;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-tooltip-wrapper > div {
  background-color: #7584c3 !important;
  border-radius: 8px !important;
  border: none !important;
}

.recharts-tooltip-item-name,
.recharts-tooltip-item-separator {
  display: none;
}

.recharts-tooltip-item-value {
  font-weight: 500;
  font-size: 13px;
}

.fixedBackArrowGNB {
  max-width: 428px;
  position: fixed;
  z-index: 500;
  top: 0;
  height: 50px;
  background-color: inherit;
  padding: 0 20px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.MuiList-padding {
  padding-top: 0 !important;
}

.MuiCircularProgress-colorPrimary {
  border: black;
  color: black;
}

.MuiDialog-root {
  z-index: 1900 !important;
}
